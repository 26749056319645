import Navbar from "./Navbar";
import CardAccept from "./CardAccept";
import { toast } from "react-toastify";

const { Flex, Text, Image, Link, Button, Card, SimpleGrid } = require("@chakra-ui/react");

const { useState, useEffect } = require("react");
const Cookies = require("js-cookie");

function AcceptList() {
    const [items, setItems] = useState([]);
    const [acceptedItems, setAcceptedItems] = useState([]);
    useEffect(() => {
        const storedItems = Cookies.get('items-to-accept');
        if (storedItems) {
          setItems(JSON.parse(storedItems));
        }
        const storedAcceptedItems = Cookies.get('accepted-items');
        if (storedAcceptedItems) {
          setAcceptedItems(JSON.parse(storedAcceptedItems));
        }
      }, []);

    const handleAccept = (key) => {
        console.log(key);
        const itemToAccept = items[key];
        if (itemToAccept) {
            const updatedAcceptedItems = [...acceptedItems, itemToAccept];
            setAcceptedItems(updatedAcceptedItems);
            Cookies.set("accepted-items", JSON.stringify(updatedAcceptedItems));

            const updatedItems = items.filter((item, index) => index !== key);
            setItems(updatedItems);
            Cookies.set("items-to-accept", JSON.stringify(updatedItems));
            toast.success("Utwór został zaakceptowany");
        }
    };

    const handleReject = (key) => {
        const updatedItems = items.filter((item, index) => index !== key);
        setItems(updatedItems);
        Cookies.set("items-to-accept", JSON.stringify(updatedItems));
        toast.error("Utwór został odrzucony");
    }


    return (
        <>
        <Navbar current={"accept"} />
            <Flex w={"90vw"} p={"20px 200px"} justifyContent={"left"} alignItems={"center"}>
                <Text fontSize={"2xl"} fontWeight={"bold"}>{items.length} Utwor(ów) do akceptacji</Text>
            </Flex>
            <SimpleGrid columns={4} gap={"30px"} p={"20px 200px"} alignItems={"center"}>
                {items.map((item, index) => (
                    <CardAccept title={item.title} artist={item.artist} link={item.link} index={index} key={index} onAccept={handleAccept} onReject={handleReject} />
                ))}
            </SimpleGrid>
        </>
    )
}

export default AcceptList;