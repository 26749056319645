import { Flex, Text, Image, Button } from "@chakra-ui/react"
import Navbar from "./Navbar"
import { FaForward, FaBackward, FaPlay, FaVolumeHigh, FaCircle, FaRotateRight } from "react-icons/fa6"

function Player() {
    return (
        <>
            <Navbar current={"player"}/>
            <Flex w={"100vw"} justifyContent={"center"}>
                <Text fontSize={"4xl"} fontWeight={"bold"}>
                    Odtwarzacz radiowęzłu
                </Text>
            </Flex>
            <Flex w={"100vw"} justifyContent={"center"} alignItems={"center"} p={"20px 200px"}>
                <Flex w={"80vw"} justifyContent={"center"} alignItems={"center"} backgroundColor={"gray.100"} borderRadius={"10px"} p={"50px 0px"} boxShadow={"xl"} direction={"column"} gap={"15px"}>
                    <Flex backgroundColor={"white"} borderRadius={"10px"} p={"20px"} w={"480px"} direction={"column"} gap={"10px"} boxShadow={"xl"}>
                        <Flex alignItems={"center"} gap={"5px"}>
                            <FaCircle color={"red"} />
                            <Text fontSize={"xl"} fontWeight={"bold"} color={"red"}>ODTWARZANIE</Text>
                            <Text fontSize={"xl"} fontWeight={"semibold"} color={"red"}>03:23</Text>
                        </Flex>
                        {/* <Flex alignItems={"center"} gap={"5px"}>
                            <FaCircle color={"gray"} />
                            <Text fontSize={"xl"} fontWeight={"bold"} color={"gray"}>Zatrzymano</Text>
                            <Text fontSize={"xl"} fontWeight={"semibold"} color={"gray"}>40:12</Text>
                        </Flex> */}
                        <Flex alignItems={"center"} gap={"5px"}>
                            <Text fontSize={"xl"} fontWeight={"bold"}>Aktualnie grane:</Text>
                            <Text fontSize={"md"} fontWeight={"semibold"}>Tytuł utworu</Text>
                        </Flex>
                        <Flex alignItems={"center"} gap={"5px"}>
                            <Text fontSize={"xl"} fontWeight={"bold"}>Następny utwór:</Text>
                            <Text fontSize={"md"} fontWeight={"semibold"}>Tytuł utworu</Text>
                        </Flex>
                    </Flex>
                    <Flex backgroundColor={"white"} borderRadius={"10px"} p={"20px"} w={"480px"} direction={"row"} justifyContent={"center"} gap={"10px"} boxShadow={"xl"}>
                        <Flex gap={"5px"} justifyContent={"center"}>
                            <Button colorScheme={"purple"}><FaVolumeHigh /></Button>
                        </Flex>
                        <Flex gap={"5px"} justifyContent={"center"}>
                            <Button colorScheme={"purple"}><FaBackward /></Button>
                            <Button colorScheme={"purple"}><FaPlay /></Button>
                            <Button colorScheme={"purple"}><FaForward /></Button>
                        </Flex>
                        <Flex gap={"5px"} justifyContent={"center"}>
                            <Button colorScheme={"purple"}><FaRotateRight /></Button>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default Player