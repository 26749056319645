import React from 'react';
import ReactDOM from 'react-dom/client';
import Homepage from './Homepage';
import Player from './Player';
import List from './List';
import App from './App';
import AcceptList from './Accept';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import { ChakraProvider } from '@chakra-ui/react'
import { ColorModeScript } from "@chakra-ui/react"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <ColorModeScript initialColorMode={{
        initialColorMode: 'light',
      }} />
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Homepage />} />
            <Route path="list" element={<List />} />
          </Route>
          <Route path="/manage" element={<App />}>
            <Route index element={<Homepage />} />
            <Route path="accept" element={<AcceptList />} />
            <Route path="player" element={<Player />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);
