import {
  Avatar,
  Flex,
  Button,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Image,
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

function App() {

  const [data, setData] = useState({
    title: "",
    artist: "",
    link: "",
  });

  const [items, setItems] = useState([]);

  const onSubmit = () => {
    if (data.title === "" || data.artist === "" || data.link === "") {
      toast.error("Wypełnij wszystkie pola");
      return;
    }
    const updatedItems = [...items, data];
    setItems(updatedItems);
    Cookies.set('items-to-accept', JSON.stringify(updatedItems), { expires: 7 });
    setData({
      title: "",
      artist: "",
      link: "",
    })
    toast.success("Propozycja utworu została wysłana");
  }

  useEffect(() => {
    const storedItems = Cookies.get('items-to-accept');
    if (storedItems) {
      setItems(JSON.parse(storedItems));
    }
  }, []);


  return (
    <>
      <Navbar current={"home"} />
      <Flex w={"100vw"} pl={"220px"}>
        <Text w={"42vw"} fontSize={"6xl"} fontWeight={"bold"}>
          Złóż propozycje utworu, który zagra na przerwie
        </Text>
      </Flex>
      <Flex
        w={"100vw"}
        justifyContent={"center"}
        alignItems={"center"}
        p={"20px 200px"}
      >
        <Flex
          w={"80vw"}
          justifyContent={"center"}
          alignItems={"center"}
          backgroundColor={"gray.100"}
          borderRadius={"10px"}
          p={"50px 0px"}
          boxShadow={"xl"}
        >
          <Flex
            backgroundColor={"white"}
            borderRadius={"10px"}
            p={"20px"}
            w={"480px"}
            direction={"column"}
            gap={"15px"}
            boxShadow={"xl"}
          >
            <FormControl>
              <FormLabel>Tytuł utworu</FormLabel>
              <Input
                type="text"
                variant={"filled"}
                h={"45px"}
                placeholder="Wprowadź tytuł"
                value={data.title}
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Wykonawca utworu</FormLabel>
              <Input
                type="text"
                variant={"filled"}
                h={"45px"}
                placeholder="Wprowadź wykonawcę"
                value={data.artist}
                onChange={(e) => setData({ ...data, artist: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Link do utworu</FormLabel>
              <Input
                type="link"
                variant={"filled"}
                h={"45px"}
                placeholder="Wprowadź link"
                value={data.link}
                onChange={(e) => setData({ ...data, link: e.target.value })}
              />
            </FormControl>
            <Button
              colorScheme="purple"
              rightIcon={<FiSend />}
              w={"30%"}
              alignSelf="flex-end"
              onClick={onSubmit} 
            >
              Wyślij
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default App;
