const { Flex, Avatar, Text, Button, Link } = require("@chakra-ui/react");

function Navbar({ pv, current }) {
    return (
        <Flex w={'100vw'} justifyContent={"center"} alignItems={"center"} p={"20px 0px"}>
            <Flex w={"90vw"} justifyContent={"space-between"} alignItems={"center"} bg={"gray.100"} p={"10px"} borderRadius={"8px"} boxShadow={"xl"}>
                <Flex alignItems={"center"} gap={"10px"}>
                    <Avatar src='/logo.png' />
                    <Text fontWeight={"bold"} fontSize={"2xl"}>Radiowęzeł FUMu</Text>
                </Flex>
                {!pv && (
                    <Flex gap={"20px"}>
                        <Button as={Link}colorScheme={"purple"} variant={current === "player" ? "solid" : "outline"} href="/manage/player">
                            Odtwarzacz
                        </Button>
                        <Button as={Link} colorScheme={"purple"} variant={current === "accept" ? "solid" : "outline"} href="/manage/accept">
                            Akceptacja utworów
                        </Button>
                        <Button as={Link} colorScheme={"purple"} variant={current === "list" ? "solid" : "outline"} href="/list">
                            Lista utworów
                        </Button>
                        <Button as={Link} colorScheme={"purple"} variant={current === "home" ? "solid" : "outline"} href="/">
                            Strona Główna
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}

export default Navbar;